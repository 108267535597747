<template>
  <div >
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <div>
        <div class="top_nav"><span></span>{{tableData.chineseName }}
        </div>
        <div class="formBody">
        
          <van-form  @submit="convert(currentUnit)">
            <div class="form_title">国际单位（推荐）</div>

            <div class="form_card" v-for="item in internationalUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]"  @blur="setCurrentUnit(item)" >
            </div>

            <div class="form_title">常用单位</div>
            <div class="form_card" v-for="item in commonUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]" @blur="setCurrentUnit(item)" >
            </div>
            <div style="margin: 16px;display: flex;justify-content: center;">
              <van-button style="width:35%;margin-right: 15px" block type="info" native-type="submit">计算</van-button>
              <van-button style="width:35%;border-color: #0091FB;color: #0091FB" block   @click="backBtn">重置</van-button>
            </div>

          </van-form>
        </div>
      </div>
        <div style=" margin-top: 15px;" v-if="tableData.explain && tableData.explain.length > 0">
        <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
          <div class="top_nav"><span></span>{{ item.name|| item.title}}</div>
          <div style="padding: 2px 10px;background: white" >
            <span v-html="item.setText||item.value">
            </span>
          </div>
        </div>
      </div>
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>
  </div>
</template>
<script>
import FormulaBanner from "@/components/FormulaBanner.vue"; //收藏顶部组件
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula} from "@/service/BodyMassIndex_api";
 import {postDataBrowse} from "@/service/api";  //埋点 先不传
 import FormulaReferences from "@/components/template1/FormulaReferences.vue";
export default {
  name: "untilTest",
  components: {FormulaBanner,FormulaReferences},
  data(){
    return{
      title:'肌酐(Cr)',
      formulaCode:'UTHMITNL',
      countText: '',
      form:{},
      tableData: {},
      internationalUnits: ['mmol/L','μmol/L'], //国家单位
      commonUnits: ['mg/dL','mg/100mL','mg%','mg/L','μg/mL'], //常用单位
      currentUnit: 'mmol/L',
      conversionFactors: {
       'mmol/L':1,
       'μmol/L':1000,
       'mg/dL':11.3126,
       "mg/100mL":11.3126,
       'mg%':11.3126,
       'mg/L':113.126,
       'μg/mL':113.126,
      }
    }
  },
  mounted() {
    this.test()
    this.getTableData()
  },
  methods:{
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      //点击埋点
      const data={
        "type":"yxgs_dwhs",//index表示在首页列表中点击，mix_index表示在专题列表中点击
        "contentId":Number(this.$route.query.id),//为vod时，根据contentId增加浏览量
        "contentType":'YXGS_DWHS',
        "location":this.$route.query.location,
        "channel":this.$route.query.channel,
        "os":arrays.os,
        "memberId":this.$route.query.memberId,
      }
      await postDataBrowse(data)
      await setById(arrays)
    },
    setCurrentUnit(unit) {
      if (this.form[unit]===null || this.form[unit]===undefined||this.form[unit]===''){
        return
      }
      this.currentUnit = unit;
      this.convert(unit)
    },
    // 点击计算执行
    convert(fromUnit) {
      const value = parseFloat(this.form[fromUnit]);
       if(fromUnit =='mmol/L') {
          this.form['mmol/L']= this.applyPrecision(value)
          this.form['μmol/L']= this.applyPrecision(value*this.conversionFactors['μmol/L'])
          this.form['mg/dL']= this.applyPrecision(value*this.conversionFactors['mg/dL'])
          this.form['mg/100mL']= this.applyPrecision(value*this.conversionFactors['mg/dL'])
          this.form['mg%']= this.applyPrecision(value*this.conversionFactors['mg/dL'])
          this.form['mg/L']= this.applyPrecision(value*this.conversionFactors['mg/L'])
          this.form['μg/mL']= this.applyPrecision(value*this.conversionFactors['mg/L'])
       }
       if(fromUnit =='μmol/L'){
        this.form['μmol/L']= this.applyPrecision(value)
        this.form['mmol/L']= this.applyPrecision(value*0.001)
        this.form['mg/dL']= this.applyPrecision(value*this.conversionFactors['mg/dL']/1000)
        this.form['mg/100mL']= this.applyPrecision(value*this.conversionFactors['mg/dL']/1000)
        this.form['mg%']= this.applyPrecision(value*this.conversionFactors['mg/dL']/1000)
        this.form['mg/L']= this.applyPrecision(value*this.conversionFactors['mg/L']/1000)
        this.form['μg/mL']= this.applyPrecision(value*this.conversionFactors['mg/L']/1000)
       }
       if(fromUnit =='mg/dL' || fromUnit =='mg/100mL' || fromUnit =='mg%'){
        this.form['mg/dL']= this.applyPrecision(value)
        this.form['mg/100mL']= this.applyPrecision(value)
        this.form['mg%']= this.applyPrecision(value)
        this.form['mmol/L']= this.applyPrecision(value * 1/this.conversionFactors['mg/dL'])
        this.form['μmol/L']= this.applyPrecision(value * 1000/this.conversionFactors['mg/dL'])
        this.form['mg/L']= this.applyPrecision(value*10)
        this.form['μg/mL']= this.applyPrecision(value*10 )
       }
       if(fromUnit =='mg/L' || fromUnit =='μg/mL'){
        this.form['mg/L']= this.applyPrecision(value)
        this.form['μg/mL']= this.applyPrecision(value)
        this.form['mg/dL']= this.applyPrecision(value*0.1)
        this.form['mg/100mL']= this.applyPrecision(value*0.1)
        this.form['mg%']= this.applyPrecision(value*0.1)
        this.form['mmol/L']= this.applyPrecision(value * 1/this.conversionFactors['mg/L'])
        this.form['μmol/L']= this.applyPrecision(value * 1000/this.conversionFactors['mg/L'])
       }          
    },
    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) { // 检查是否有小数
        const roundedValue = floatValue.toFixed(4); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    backBtn(){
      this.form={}
    },
    // 获取说明
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'UTHMITNL',
      }
      const res = await getFormula(data.channel,data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
      }
      console.log(this.tableData.explain);
    },
  },
}
</script>



<style scoped lang="scss">

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: auto;
  min-height: 100vh;
  font-size: 16px;
  padding-bottom: 20px;
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding:10px;

    .form_title{
      padding: 5px 8px;
      background: #E8F5FF;
    }
    .form_card{
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      margin: 10px ;
      align-items: center;
      .form_card_input{
        border-radius: 3px;
        min-height: 25px;
        border: 1px solid #E3E3E3;
      }
    }



    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>